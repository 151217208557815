<template lang="pug">
label.linput-file
  input(
    type="file"
    ref="ipt"
    @change="$emit('change', $refs.ipt)"
    :multiple="multiple"
    :accept="accept"
  )
  slot(name="default")

</template>

<script>

export default {
  props: {
    preloader: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: '.png,.jpg,.jpeg'
    }
  },
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>

<style lang="scss">
.linput-file {
  display: inline-block;
  & input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}
</style>
