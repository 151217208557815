<template lang="pug">
.lcard(:class="[{'lcard_full-view': fullView }]")
  .lcard__header
    .lcard__user(@click="$router.push(`/app/user/${data.author.uuid}`)" style="cursor:pointer;")
      LAvatar(:url="data.author.photo.preview")
      .lcard__user-info
        .lcard__user-name {{ data.author.name || data.author.username }}
        .lcard__user-lastseen {{ lastSeen(data.created_at) }}

    template(v-if="canFollow && !canEdit")
      LButton.lcard__follow(
        size="small"
        @click="followUser"
        v-if="!data.author.is_follow"
        :preloader="followPreloader"
      ) {{ $t('Подписаться') }}
      LButton.lcard__follow(
        size="small"
        @click="unfollowUser"
        v-if="data.author.is_follow"
        theme="grey"
        :preloader="followPreloader"
      ) {{ $t('Отписаться') }}

    template(v-if="canEdit")
      <router-link :to="postEditUrl" style="margin-left: auto;">
        <svg width="24" height="24" viewBox="-5 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="lcard__edit">
          <path d="M12.2968 16.6829L10.8469 17.0543C10.7437 17.0808 10.6375 17.0941 10.531 17.0941C10.192 17.0941 9.87315 16.9625 9.63337 16.7233C9.31752 16.4083 9.19036 15.9446 9.30147 15.5131L9.33443 15.3852H8.10621C7.68486 15.3852 7.34328 15.0445 7.34328 14.6243C7.34328 14.2041 7.68486 13.8635 8.10621 13.8635H9.7375C9.84382 13.5747 10.0122 13.3113 10.2314 13.0926L10.8012 12.5243H8.10621C7.68486 12.5243 7.34328 12.1837 7.34328 11.7635C7.34328 11.3432 7.68486 11.0026 8.10621 11.0026H12.3271L13.6709 9.66237C13.6574 9.66307 13.6438 9.66346 13.6301 9.66346H8.10624C7.68489 9.66346 7.34331 9.3228 7.34331 8.90259C7.34331 8.48238 7.68489 8.14172 8.10624 8.14172H13.6301C14.0514 8.14172 14.393 8.48238 14.393 8.90259C14.393 8.91626 14.3926 8.92986 14.3919 8.94334L16.2429 7.09735C16.3911 6.94953 16.5573 6.82624 16.7363 6.72888V5.8627C16.7363 5.38625 16.349 5 15.8713 5H5.86504C5.38729 5 5 5.38625 5 5.8627V18.1373C5 18.6138 5.38729 19 5.86504 19H15.8713C16.349 19 16.7363 18.6138 16.7363 18.1373V12.6739L13.2739 16.127C13.0042 16.396 12.6663 16.5882 12.2968 16.6829Z" fill="#C31A14"/>
          <path d="M10.8787 13.7385C10.7247 13.8921 10.6149 14.0841 10.5607 14.2945L10.1882 15.7405C10.1571 15.8614 10.1923 15.9897 10.2807 16.0779C10.348 16.145 10.4383 16.1813 10.531 16.1813C10.5604 16.1813 10.5899 16.1776 10.6191 16.1702L12.069 15.7987C12.28 15.7447 12.4725 15.6351 12.6265 15.4815L17.5497 10.5716L15.8019 8.8285L10.8787 13.7385Z" fill="#C31A14"/>
          <path d="M18.638 7.74289C18.3967 7.50221 18.0804 7.38188 17.7641 7.38188C17.4479 7.38188 17.1315 7.50221 16.8902 7.74289L16.449 8.18292L18.1946 9.92823L18.6381 9.48595C19.1207 9.00463 19.1207 8.22422 18.638 7.74289Z" fill="#C31A14"/>
        </svg>
      </router-link>


  .lcard__content
    Swiper.lcard__gallery.mt-2(v-if="hasGallery" :options="swiperOption" ref="swiperRef")
      SwiperSlide(v-if="videoGallery.length > 0" v-for="video, index in videoGallery")
        LVideo(
          :src="video.original"
          :preview="video.preview"
          @inControls="$refs.swiperRef.$swiper.allowTouchMove = false"
          @outControls="$refs.swiperRef.$swiper.allowTouchMove = true"
        )

      SwiperSlide(v-if="imageGallery.length > 0" v-for="image, index in imageGallery")
        img.lcard__gallery-preview(:src="image.preview")

      template(v-if="(imageGallery.length + videoGallery.length) > 1")
        .swiper-button-prev(
          slot="button-prev"
          @click="slidePrev"
          v-if="showPrev"
        )
        .swiper-button-next(
          slot="button-next"
          @click="slideNext"
          v-if="showNext"
        )


    h2.lcard__content-title(@click="$router.push(`/app/post/${data.uuid}`)") {{ data.title }}
    article.lcard__content-description {{ description }}
    span.lcard__content-readmore(v-if="readmore" @click="showReadMore = !showReadMore") {{ $t('Read more') }}...

  .lcard__footer
    .lcard__footer-item(@click="setLike")
      .lcard__footer-item-icon(style="background-color: #C31A14;")
        LIcon(name="favorite" color="#fff")
      span {{ getCount(likes) }}

    .lcard__footer-item(style="margin-left: 20px;" @click="showComments = true")
      .lcard__footer-item-icon(style="background-color: #757373;")
        LIcon(name="message" color="#fff")
      span {{ getCountComments }}

    .lcard__footer-item(style="margin-left: auto;" @click="sharePopup = true")
      .lcard__footer-item-icon(style="background-color: #0A156A;")
        LIcon(name="share" color="#fff")

  .lcard__comments(v-if="showComments")
    .lcard__comments-view
      .lcard__comments-view-scroll

        //- COMMENT
        .lcard__comments-item(v-for="comment, index in comments")
          .lcard__comments-item-head
            .lcard__comments-item-author
              LAvatar(:url="comment.author.photo.preview")
              .lcard__comments-item-author-info
                span {{ comment.author.name }}
                span {{ comment.adv ? 'рекламодатель' : 'пользователь' }}

            .lcard__comments-item-time {{ lastSeen(comment.created_at) }}

          .lcard__comments-item-body
            .lcard__comments-item-message {{ comment.message }}
            .lcard__comments-item-controls 
              div(@click="setAnswer(index)") ответить
              div
                span {{ comment.post.likes }}
                <svg @click="likeCommentHandler(index)" width="20" height="20" viewBox="0 0 20 20" :fill="comment.like ? '#C91818' : 'none'" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.30835 5.32215L9.75 6.15424L10.1916 5.32215C10.7263 4.31486 11.2314 3.61984 11.8275 3.17117C12.4083 2.73409 13.1173 2.5 14.1254 2.5C16.2932 2.5 18 4.1486 18 6.11719C18 8.00329 16.9943 9.95007 15.3748 11.9825C13.845 13.9024 11.8255 15.8294 9.75 17.7839C7.67454 15.8294 5.65503 13.9024 4.12525 11.9825C2.50574 9.95007 1.5 8.00329 1.5 6.11719C1.5 4.1486 3.20675 2.5 5.37455 2.5C6.38266 2.5 7.09173 2.73409 7.67248 3.17117C8.26864 3.61984 8.77371 4.31486 9.30835 5.32215Z" :stroke="comment.like ? 'none' : '#757373'"/>
                </svg>

          //- END COMMENT
          //- div( style="padding-left: 70px; padding-top: 30px;")

        <infinite-loading @infinite="getCommentsList" :identifier="commentsId">
          template(slot="spinner")
            .profile-partners__preloader preload

          template(slot="no-more")
            p

          template(slot="no-results")
            p

        </infinite-loading>


    .lcard__comments-message
      LInput(placeholder="Сообщение" @enter="submit" v-model="message")

  LSharePopup(v-if="sharePopup" @close="sharePopup = false" :link="getShareLink")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { eventBus } from '@/libraries/eventBus'
export default {
  props: {
    data: {
      type:  [Object, Array],
      default: () => []
    },
    canFollow: {
      type: Boolean,
      default: true
    },
    fullView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar'),
    LSharePopup: () => import(/* webpackChunkName: "ui" */ '@/components/composite/LSharePopup'),
    LVideo: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LVideo'),
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput'),
    Swiper,
    SwiperSlide
  },
  mounted () {
    this.setHasReadmore()
    this.$refs.swiperRef?.$swiper.eventsListeners.slideChange.push(this.swiperChange)
    console.log(this.data)
  },
  created () {
    this.likes = this.data.likes
    if (this.fullView) this.showComments = true
  },
  data () {
    return {
      swiperOption: {
        autoHeight: true, //enable auto height
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      message: null,
      readmore: false,
      description: null,
      showReadMore: false,
      followPreloader: false,
      sharePopup: false,
      showPrev: false,
      showNext: true,
      likes: 0,
      comments: [],
      commentsCursor: null,
      commentsId: 1,
      showComments: false,
      newCommentsCount: 0
    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS']),
    getCountComments () {
      return this.data.comments + this.newCommentsCount
    },
    getShareLink () {
      return `${this.data.uuid}`
    },
    canEdit () {
      if (this.SETTINGS  && this.SETTINGS .uuid !== undefined && this.SETTINGS.uuid !== null) {
        return this.SETTINGS.uuid === this.data.author.uuid
      }
    },
    
    imageGallery () {
      let result = []
      this.data.media.forEach(item => {
        if (item.type === 'image') result.push(item)
      })
      return result
    },
    videoGallery () {
      let result = []
      this.data.media.forEach(item => {
        if (item.type === 'video') result.push(item)
      })
      return result
    },
    hasGallery () {
      return this.videoGallery.length > 0 || this.imageGallery.length > 0
    },
    postEditUrl () {
      return `/app/profile/post/${this.data.uuid}/edit`
    }
  },
  methods: {
    ...mapActions('user', ['getUserData', 'follow', 'unfollow']),
    ...mapActions('posts', ['like', 'getComments', 'addComment', 'likeComment']),
    lastSeen (dateParam) {
      let now = new Date()
      let lastseen = new Date(dateParam)
      let difference = now - lastseen
      let seconds = (difference / 1000 + 1).toFixed(0)
      let minutes = (difference / (1000 * 60)).toFixed(0)
      let hours = (difference / (1000 * 60 * 60)).toFixed(0)


      let fMinutes = lastseen.getMinutes()
      fMinutes = (fMinutes.toString().length === 1) ? `0${fMinutes}` : fMinutes

      let fHours = lastseen.getHours()
      fHours = (fHours.toString().length === 1) ? `0${fHours}` : fHours

      let fDays = lastseen.getDay()
      fDays = (fDays.toString().length === 1) ? `0${fDays}` : fDays

      let fMonth = lastseen.getMonth() + 1
      fMonth = (fMonth.toString().length === 1) ? `0${fMonth}` : fMonth

      if (minutes <= 59) {
        if (minutes < 1) {
          return `${seconds} seconds ago`
        } else {
          return `${minutes} minutes ago`
        }
      } else {
        return `${fDays}.${fMonth}.${lastseen.getFullYear()} ${fHours}:${fMinutes}`
      }
    },
    submit () {
      console.log('SEND COMMENT', this.SETTINGS)
      let messageBuffer = this.message
      this.message = null
      this.addComment({ uuid: this.data.uuid,message: messageBuffer })
      .then(res => {
        console.log(res)
        this.newCommentsCount += 1
        this.comments.push({
          adv: this.SETTINGS.type === 'user' ? false : true,
          author: {
            photo: this.SETTINGS.photo,
            type: this.SETTINGS.type,
            name: this.SETTINGS.name
          },
          name: this.SETTINGS.name,
          like: false,
          message: messageBuffer,
          created_at: Date.now(),
          post: {
            is_like: false,
            likes: 0,
            text: null,
            title: null
          }
        })
      })

      

    },
    getCount (value) {
      return value
    },
    swiperChange () {
      this.showPrev = !this.$refs.swiperRef.$swiper.isBeginning
      this.showNext = !this.$refs.swiperRef.$swiper.isEnd
      eventBus.$emit('setPausedVideo')
    },
    slidePrev () {
      this.$refs.swiperRef.$swiper.slidePrev()
      this.showPrev = !this.$refs.swiperRef.$swiper.isBeginning
      this.showNext = !this.$refs.swiperRef.$swiper.isEnd
    },
    slideNext () {
      this.$refs.swiperRef.$swiper.slideNext()
      this.showNext = !this.$refs.swiperRef.$swiper.isEnd
      this.showPrev = !this.$refs.swiperRef.$swiper.isBeginning
    },
    setHasReadmore () {
      if (this.data.text !== null && this.data.text.length > 180) {
        this.readmore = true
        this.cutDescription(180)
      } else {
        this.cutDescription()
      }
    },
    cutDescription (length = 0) {
      if (length === 0) this.description = this.data.text
      else this.description = this.data.text.substr(0, length)
    },
    followUser () {
        this.followPreloader = true
        this.follow(this.data.author.uuid)
        .then((response) => {
          if (response.data.result.status) {
            this.followPreloader = false
            this.$emit('follow', {
              uuid: this.data.author.uuid,
              status: true
            })
          }
        })
      },
    unfollowUser () {
      this.followPreloader = true
      this.unfollow(this.data.author.uuid)
      .then((response) => {
        if (response.data.result.status) {
          this.followPreloader = false
          this.$emit('follow', {
            uuid: this.data.author.uuid,
            status: false
          })
        }
      })
    },
    setLike () {
      this.like(this.data.uuid)
      .then(res => {
        if (res.data.data.is_like) this.likes += 1
        else this.likes -= 1


        console.log(res)
      })
    },
    getCommentsList (loader) {
      this.getComments({ postUuid: this.data.uuid, cursor: this.commentsCursor })
      .then(response => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.comments.push(...response.data.data.data)
        console.log('COMMENTS', this.comments)
        if (!!response.data.data.meta && response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.commentsCursor = response.data.data?.meta?.cursor || null
        }
      })
    },
    setAnswer (index) {
      if (this.message === null) {
        this.message = ''
      }
      this.message = this.comments[index].author.name + ', ' + this.message
    },
    likeCommentHandler (index) {
      this.likeComment(this.comments[index].uuid)
      .then(res => {
        console.log(res)
        let is_like = res.data.data.is_like
        let likes = this.comments[index].post.likes
        let updateData = this.comments[index]
        likes = (is_like) ? likes += 1 : likes -= 1
        updateData.post.likes = likes
        updateData.post.is_like = is_like
        updateData.like = is_like
        this.comments.splice(index, updateData)
      })
    }
  },
  watch: {
    showReadMore (newVal) {
      if (newVal) {
        this.cutDescription()
        this.readmore = false
      } else {
        this.cutDescription(180)
      }
    }
  }
}
</script>

<style lang="scss">

.lcard {
  background: #fff;
  border-radius: 10px;
  padding: 40px 60px;
  margin-bottom: 30px;

  &__header {
    display: flex;
    align-items: center;
  }
  &__user {
    display: flex;
    &-info {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      justify-content: center;
    }
    &-name {
      font-weight: 700;
      font-size: 16px;
      padding-bottom: 8px;
    }
    &-lastseen {
      color: #757373;
      font-size: 10px;
    }
    & .lavatar {
      width: 60px;
      height: 60px;
    }
  }

  &__follow {
    margin-left: auto;
    text-transform: uppercase;
  }
  &__edit {
    margin-left: auto;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  &__content {
    &-description {
      overflow-wrap: anywhere;
      line-height: 24px;
    }
    &-readmore {
      text-decoration: underline;
      color: #0A156A;
      font-size: 18px;
      cursor: pointer;
      line-height: 50px;
    }
    &-title {
      overflow-wrap: anywhere;
      margin-bottom: 15px;
      margin-top: 30px;
    }
  }
  &__gallery {
    width: 100%;
    overflow: hidden;
    position: relative;
    &-preview {
      width: 100%;
      max-width: 100%;
      display: block;
    }
  }
  &__footer {
    display: flex;
    margin-top: 30px;
    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      & span {
        margin-left: 10px;
      }
      &-icon {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ccc;
        border-radius: 50%;
      }
    }
  }
  &__comments {
    padding-top: 50px;
    &-view {
      max-height: 500px;
      overflow-y: auto;
      &-scroll {
      }
    }
    &-message {
      padding-top: 30px;
      & .linput {
        background: #FFFFFF;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 30px;
        height: 35px;
        font-size: 14px;
        & > input::placeholder {
          font-size: 14px;
          text-transform: none;
          color: #ccc;
        }
      }

    }
    &-item {
      margin-bottom: 25px;
      &-controls {
        display: flex;
        align-items: center;
        padding-top: 5px;
        justify-content: space-between;
        & span {
          color: #757373;
          margin-right: 3px;
        }
        & svg {
          cursor: pointer;
        }
        & div {
          &:first-child {
            cursor: pointer;
            font-size: 14px;
          }
          &:last-child {
            display: flex;
            align-items: center;
          }
        }
      }
      &-body {
        padding-left: 70px;
      }
      &-head {
        display: flex;

      }
      &-time {
        font-size: 10px;
        line-height: 12px;
        color: #757373;
        margin-left: auto;
      }
      &-message {
        background: #FAF9F9;
        padding: 16px;
        margin-top: 10px;
      }
      &-author {
        display: flex;
        & .lavatar {
          width: 50px;
          height: 50px;
          margin-right: 20px;
        }
        &-info {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          & span {
            display: block;
            font-size: 14px;
            &:first-child {
              font-weight: bold;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  &_full-view {
    & .lcard__comments-view {
      max-height: unset;
      overflow-y: hidden;
    }
  }
}
</style>
