<template lang="pug">
.laccordion(
    :class="[{'laccordion__show': show }]"
  )
  .laccordion__title(@click="show = !show")
    span
      slot(name="title")
    LIcon(name="arrow-down" color="#E12323")

  .laccordion__list
    slot(name="list")


</template>

<script>

export default {
  props: {
  },
  components: {
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
  },
  data () {
    return {
      show: true
    }
  }
}
</script>

<style lang="scss">
.laccordion {
  overflow: hidden;
  height: 24px;
  margin-bottom: 30px;

  & .licon {
    transform: rotate(270deg);
  }

  &__show {
    height: auto;
    & .licon {
      transform: rotate(0deg);
    }
  }
  &__title {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    & .licon {
      margin-left: auto;
    }
  }
}
</style>
