<template lang="pug">
portal(to="popups" :disabled="disabledPortal")
  .lpopup(
      @click.self="$emit('close')"
    )
      .lpopup__body
        slot

</template>

<script>
// fix body scroll

export default {
  name: 'LPopup',
  props: {
    disabledPortal: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    // this.body = window.document.getElementsByTagName('body')[0]
    // this.body.style.overflow = 'hidden'
    // this.$emit('open')
  },
  data() {
    return {
      body: null
    }
  },
  beforeDestroy() {
    //this.body.removeAttribute("style")
  }
}
</script>

<style lang="scss">
.lpopup {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(196, 196, 196, .5);
  z-index: 100001;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow-y: auto;
  &__body {
    width: 96%;
    cursor: default;
    background: #FAF9F9;
    padding: 55px 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    @media (min-width: 768px) {
      width: auto;
      max-width: 440px;
      padding: 55px;
    }
  }
}

</style>