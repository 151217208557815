<template lang="pug">
.lvideo(ref="videoContainer" @mousemove="reshowControls(); showControls = true")
  template(v-if="preview !== null && !played")
    .lvideo__preview-screen(:style="getPreviewStyle" @click="play")
      .lvideo__preview-screen-play
        LIcon(name="play-filled" color="#fff")

  .lvideo__screen
    video(ref="video" :class="{ 'lvideo__screen_fullscreen': fullscreen }" preload="none")
      source(type="video/mp4" :src="src")

  .lvideo__controls(
    :class="{ 'lvideo__controls_fullscreen': fullscreen}"
    v-show="showControls"
    @mousemove="showControls = true; inControls = true; $emit('inControls')"
    @mouseleave="reshowControls(); inControls = false; $emit('outControls')"
  )
    .lvideo__controls-playable
      LIcon(name="play" v-if="!played" @click="play")
      <svg class="licon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="played" @click="pause">
        <path d="M14 17.231H15.6758L15.6758 6.00003H14L14 17.231Z" fill="black"/>
        <path d="M8.00003 17.231H9.67578L9.67578 6.00003H8.00003L8.00003 17.231Z" fill="black"/>
      </svg>

      LIcon(name="backward" @click="backward")
      LIcon(name="forward" @click="forward")

    .lvideo__controls-progress(
    )
      VueSlider(
        v-model="progress"
        width="100%"
        height="2px"
        @change="rewind"
        dotSize="8"
        tooltip="none"
        :railStyle="railStyle"
        :processStyle="processStyle"
        :dotStyle="dotStyle"
      )

    .lvideo__controls-views
      .lvideo__controls-volume.licon
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="toggleMute">
          <path d="M10.1947 9.60889V10.9499C10.1947 11.788 10.1947 12.2909 10.1947 14.3024L8.85416 13.2967L7.01085 11.9557L8.85416 10.6147L10.1947 9.60889ZM11.8705 6.25635L7.84871 9.106H5V14.6377H7.84871L11.8705 17.4873C11.8705 8.93837 11.8705 13.1291 11.8705 6.25635Z" fill="black"/>
          <path d="M15.3874 17.318C15.0522 17.318 14.8847 17.1504 14.7171 16.9828C14.5495 16.6475 14.7171 16.3123 15.0523 16.1447C15.555 15.977 16.0577 15.6418 16.5604 15.1389C16.8956 14.8036 17.2307 14.3008 17.5659 13.6303C17.7335 13.1274 17.901 12.4569 17.901 11.7864C17.901 11.1159 17.7335 10.613 17.5659 9.94249C17.3983 9.43961 17.0632 8.93675 16.5604 8.43387C16.2253 8.09862 15.7226 7.76335 15.0523 7.4281C14.7171 7.26048 14.5495 6.92522 14.7171 6.58997C14.8847 6.25472 15.2198 6.0871 15.555 6.25473C16.2253 6.58998 16.8956 6.92523 17.3983 7.59574C17.901 8.09862 18.4037 8.76913 18.7389 9.43963C19.074 10.1101 19.2416 10.9483 19.2416 11.7864C19.2416 12.6245 19.074 13.295 18.7389 14.1331C18.4037 14.8036 18.0686 15.4742 17.3983 15.977C16.8956 16.4799 16.2253 16.9828 15.555 17.318C15.555 17.318 15.555 17.318 15.3874 17.318Z" fill="black"/>
          <path d="M14.216 14.6363C14.0484 14.6363 13.7132 14.4687 13.7132 14.301C13.5456 13.9658 13.7132 13.6305 14.0484 13.4629C14.2159 13.2953 14.3835 13.2953 14.5511 13.1277C14.7187 12.96 14.8863 12.7924 14.8863 12.6248C15.0538 12.4572 15.0538 12.1219 15.0538 11.9543C15.0538 11.7867 15.0538 11.4514 14.8863 11.2838C14.7187 11.1162 14.7187 10.9485 14.5511 10.7809C14.3835 10.6133 14.2159 10.4457 14.0484 10.4457C13.7132 10.278 13.5456 9.9428 13.7132 9.60755C13.8808 9.2723 14.2159 9.10468 14.5511 9.2723C14.8862 9.43993 15.2214 9.60754 15.5566 9.94279C15.8917 10.278 16.0593 10.6133 16.2269 10.9485C16.3944 11.2838 16.3944 11.7867 16.3944 12.1219C16.3944 12.4572 16.3944 12.96 16.2269 13.2953C16.0593 13.6305 15.8917 13.9658 15.5566 14.301C15.2214 14.6363 14.8862 14.8039 14.5511 14.9715C14.3835 14.6363 14.216 14.6363 14.216 14.6363Z" fill="black"/>
          <path d="M7.66034 19.6566L19.5317 5" v-if="muted" stroke="#FF1017" stroke-width="2"/>
        </svg>
        .lvideo__controls-volume-wrapper
          VueSlider(
            v-model="volume"
            direction="btt"
            width="2px"
            tooltip="none"
            :railStyle="railStyle"
            :processStyle="processStyle"
            :dotStyle="dotStyle"
            dotSize="8"
            @change="setVolume"
          )

      <svg width="24" height="24" viewBox="0 0 24 24" v-if="!fullscreen" @click="setFullscreen" fill="none" class="licon" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.71429 10.2857H6V6H10.2857V7.71429H7.71429V10.2857ZM6 13.7143H7.71429V16.2857H10.2857V18H6V13.7143ZM16.2857 16.2857H13.7143V18H18V13.7143H16.2857V16.2857ZM13.7143 7.71429V6H18V10.2857H16.2857V7.71429H13.7143Z" fill="black"  />
      </svg>
      <svg width="24" height="24" viewBox="0 0 24 24" v-if="fullscreen" @click="exitFullscreen" class="licon" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 8.57143H8.57143V6H10.2857V10.2857H6V8.57143ZM8.57143 15.4286H6V13.7143H10.2857V18H8.57143V15.4286ZM13.7143 18H15.4286V15.4286H18V13.7143H13.7143V18ZM15.4286 6V8.57143H18V10.2857H13.7143V6H15.4286Z" fill="black"/>
      </svg>



</template>

<script>
  // import the styling, css or scss
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import throttle from 'lodash.throttle'
import { eventBus } from '@/libraries/eventBus'
export default  {
  props: {
    src: {
      type: String,
      default: null
    },
    preview: {
      type: String,
      default: null
    }
  },
  components: {
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    VueSlider
  },
  created () {
    eventBus.$on('setPausedVideo', () => {
      this.pause()
    })
  },
  mounted () {
    this.$refs.video.ontimeupdate = this.seeking
    document.addEventListener('mozfullscreenchange', this.fullscreenChange, false);
    document.addEventListener('fullscreenchange', this.fullscreenChange, false);
    document.addEventListener('webkitfullscreenchange', this.fullscreenChange, false);
  },
  data () {
    return {
      progress:0,
      volume: 70,
      played: false,
      muted: false,
      progressTimer: null,
      fullscreen: false,
      showControls: true,
      inControls: false,
      railStyle: {
        backgroundColor: '#fff',
        boxShadow: 'inset 0.5px 0.5px 3px 1px rgba(0,0,0,.36)'
      },
      processStyle: {
        backgroundColor: '#FF1017'
      },
      dotStyle: {
        backgroundColor: '#FF1017',
        boxShadow: 'none'
      }
    }
  },
  computed: {
    type () {
      const fileType = this.src.split(/[#?]/)[0].split('.').pop().trim();
      return `video/${fileType}`
    },
    getPreviewStyle () {
      return 'background-image: url(' + this.preview + ');'
    }
  },
  methods: {
    isFullscreen () {
      return document.webkitIsFullScreen || document.mozFullScreen || document.fullscreen
    },
    fullscreenChange (e) {
      if (!this.isFullscreen()) {
        this.fullscreen = false
        this.showControls = true
      }
    },
    play () {
      eventBus.$emit('setPausedVideo')
      this.played = true
      this.$refs.video.play()
    },
    rewind (event) {
      try {
        this.$refs.video.currentTime = (this.$refs.video.duration * event) / 100
      }
      catch (e) {

      }
    },
    pause () {
      this.played = false
      try {
        this.$refs.video.pause()
      } catch (e) {}
      clearInterval(this.progressTimer)
    },
    backward () {
      this.$refs.video.currentTime -= 5
    },
    forward () {
      this.$refs.video.currentTime += 5
    },
    setVolume (volume) {
      if (volume > 0) {
        this.muted = false
        this.$refs.video.muted = false
      } else {
        this.muted = true
        this.$refs.video.muted = true
      }
      this.volume = volume
      this.$refs.video.volume = volume / 100
      
    },
    toggleMute () {
      this.$refs.video.muted = !this.$refs.video.muted
      this.muted = this.$refs.video.muted
      if (this.muted) {
        this.volume = 0
      } else {
        this.volume = 70
      }
    },
    seeking (video) {
      var duration = this.$refs.video.duration
      this.progress = Math.floor((this.$refs.video.currentTime * 100) / duration)
    },
    reshowControls: throttle(function() {
      setTimeout(() => {
        if (this.fullscreen && !this.inControls) this.showControls = false
      }, 5000)
    }, 2000, { 'trailing': false }),

    exitFullscreen () {
      if(this.isFullscreen()) {
        const elem = this.$refs.videoContainer
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { 
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { 
          document.msExitFullscreen();
        } else if (document.mozExitFullScreen) {
          document.mozExitFullScreen();
        }
      }
    },
    setFullscreen () {
      const elem = this.$refs.videoContainer
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { 
        elem.msRequestFullscreen();
      }
      this.fullscreen = true
      this.reshowControls()
    }
  },
  watch: {
    progress (newVal) {
      if (newVal === 100) {
        this.played = false
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('mozfullscreenchange', this.fullscreenChange)
    document.removeEventListener('fullscreenchange', this.fullscreenChange)
    document.removeEventListener('webkitfullscreenchange', this.fullscreenChange)
  }
}
</script>

<style lang="scss">
.lvideo {
  background: #000;
  width: 100%;
  height: 400px;
  position: relative;
  &__preview-screen {
    width: 100%;
    position: absolute;
    z-index: 10;
    height: calc(100% - 42px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &-play {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, .3);
      display: flex;
      align-items: center;
      justify-content: center;
      & .licon {
        width: 84px;
        height: 84px;
      }
    }
  }
  &__screen {
    display: flex;
    justify-content: center;
    height: calc(100% - 42px);
    &_fullscreen {
      height: 100%;
    }
  }
  &__controls {
    height: 42px;
    display: flex;
    align-items: center;
    background: #fff;
    position: relative;
    z-index: 11;
    &_fullscreen {
      position: absolute;
      z-index: 2147483647;
      bottom: 0px;
      width: 670px;
      left: calc(50% - 335px);
    }
    & .licon {
      cursor: pointer;
    }
    &-volume {
      position: relative;
      overflow: hidden;
      &:hover {
        overflow: visible;
        & .lvideo__controls-volume-wrapper {
          box-shadow: 5px 5px 10px rgba(0,0,0,.3);
        }
      }
      &-wrapper {
        background: #fff;
        width: 44px;
        height: 120px;
        position: absolute;
        top: -120px;
        left: -10px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        padding: 10px 0;
      }
    }
    &-playable {
      width: 20%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    &-progress {
      width: 60%;
      & .slider-dot {
        box-shadow: none !important;
        background-color: #FF1017 !important;
      }
    }
    &-views {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}
</style>
