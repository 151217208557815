<template lang="pug">
.luser-view
  template(v-if="data === null")
    LUserGhost

  template(v-else)
    .luser-view__header
       LAvatar(:url="data.photo.preview")
       p.mt-1.luser-view__header-name {{ data.name }}
       p.mt-1.mb-3(style="font-size: 14px;") {{ $t(`${data.type}`) }}

    .luser-view__actions.mb-2(v-if="!isOwner")
      LButton.luser-view__actions-button.luser-view__actions-button_left(
        size="small"
        @click="setFriendUser"
        theme="blue"
        v-if="data.is_friend.toString() === 'false'"
      ) {{ $t('дружить') }}
      LButton.luser-view__actions-button.luser-view__actions-button_left(
        size="small"
        theme="disabled"
        v-if="data.is_friend.toString() === 'request'"
      ) {{ $t('Запрос отправлен') }}
      //- hack for flex
      .luser-view__actions-button.luser-view__actions-button_left(v-if="data.is_friend.toString() === 'accept'")
      LIcon(name="message" color="#C91818")
      template(v-if="data.is_friend.toString() === 'accept' && notMe")
        LButton.luser-view__actions-button.luser-view__actions-button_right(
          size="small"
          @click="removeFriendUser"
          theme="grey"
        ) {{ $t('Удалить из друзей') }}

      template(v-else)
        LButton.luser-view__actions-button.luser-view__actions-button_right(
          size="small"
          @click="followUser"
          v-if="!data.is_follow"
          :preloader="followPreloader"
        ) {{ $t('подписаться') }}
        LButton.luser-view__actions-button.luser-view__actions-button_right(
          size="small"
          @click="unfollowUser"
          v-if="data.is_follow"
          theme="grey"
          :preloader="followPreloader"
        ) {{ $t('отписаться') }}

</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LUser',
  components: {
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LUserGhost: () => import(/* webpackChunkName: "ghosts" */ '@/components/ghosts/LUserGhost'),
  },
  props: {
    uuid: {
      type: String,
      default: null
    },
    isPartner: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.getData()
  },
  data () {
    return {
      data: null,
      followPreloader: false
    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS']),
    isOwner () {
      return this.uuid === this.SETTINGS.uuid
    }
  },
  methods: {
    ...mapActions('user', ['getUserData', 'follow', 'unfollow', 'setFriend', 'removeFriend', 'getPartnerData']),
    getData () {
      if (this.isPartner) {
        this.getPartnerData(this.uuid)
        .then((response) => {
          console.log('PART', response)
          this.data = response.data.data.data
        })
      } else {
        this.getUserData(this.uuid)
        .then((response) => {
          console.log('PART', response)
          this.data = response.data.data.data
        })
      }
    },
    followUser () {
      this.followPreloader = true
      this.follow(this.uuid)
      .then((response) => {
        if (response.data.data.following) {
          this.followPreloader = false
          this.$set(this.data, 'is_follow', true)
        }
      })
    },
    unfollowUser () {
      this.followPreloader = true
      this.unfollow(this.uuid)
      .then((response) => {
        if (!response.data.data.following) {
          this.followPreloader = false
          this.$set(this.data, 'is_follow', false)
        }
      })
    },
    setFriendUser () {
      this.setFriend(this.uuid)
      .then((response) => {
        this.$set(this.data, 'is_friend', 'request')
        this.$set(this.data, 'is_follow', true)
      })
    },
    removeFriendUser () {
      this.removeFriend(this.uuid)
      .then((response) => {
        this.$set(this.data, 'is_friend', 'false')
        this.$set(this.data, 'is_follow', false)
      })
    }
  }
}
</script>

<style lang="scss">
.luser-view {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: space-between;
    &-name {
      font-weight: 700;
    }
    & .lavatar {
      width: 85px;
      height: 85px;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    &-button {
      text-transform: uppercase;
      width: 200px;
      &_left {
        margin-left: 30px;
      }
      &_right {
        margin-right: 30px;
      }
    }
    & .licon {
      margin: 0 auto;
      height: 38px;
      width: 38px;
      cursor: pointer;
    }
  }

}
</style>