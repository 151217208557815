<template lang="pug">
.lbutton(
    :class="[`lbutton_theme_${theme}`, `lbutton_size_${size}`, {'lbutton_disabled': disabled || preloader }]"
    @click.capture="click()"
  )
  template(v-if="!preloader")
    slot(name="default")

  template(v-else)
    LPreloader

</template>

<script>

export default {
  components: {
    LPreloader: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPreloader')
  },
  props: {
    theme: {
      type: String,
      default: 'red'
    },
    size: {
      type: String,
      default: "default"
    },
    preloader: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click () {
      // if preloader true, click not emitted
      if (!this.preloader) this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
.lbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  letter-spacing: .03em;
  flex-wrap: nowrap;
  white-space: nowrap;
  &_theme {
    &_red {
      color: #fff;
      background: #C91818;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 0 35px;
      &:hover:not(.lbutton_disabled) {
        background: #E12323;
      }
      &:active:not(.lbutton_disabled) {
        background: #E12323;
        box-shadow: none;
      }
    }
    &_blue {
      color: #FAF9F9;
      background: #0A156A;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 0 35px;
      &:hover:not(.lbutton_disabled) {
        background: #132297;
      }
      &:active:not(.lbutton_disabled) {
        background: #132297;
        box-shadow: none;
      }
    }
    &_grey {
      color: #fff;
      background-color: #757373;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 0 35px;
      &:hover:not(.lbutton_disabled) {
        background: #9A9A9A;
      }
      &:active:not(.lbutton_disabled) {
        background: #9A9A9A;
        box-shadow: none;
      }
    }
    &_disabled {
      color: #fff;
      background-color: #757373;
      cursor: default;
      padding: 0 35px;
    }
    &_transparent {
      color: #000;
      padding: 0 12px;
    }
  }

  &_size {
    &_micro {
      height: 13px;
      border-radius: 2px;
      box-shadow: unset;
      font-weight: bold;
      font-size: 9px;
      padding: 0 8px;
      & .lpreloader {
        height: 8px;
        width: auto;
      }
    }
    &_small {
      height: 30px;
      border-radius: 5px;
      font-size: 16px;
    }
    &_default {
      height: 60px;
      border-radius: 10px;
      text-transform: uppercase;
    }
  }
}
</style>
