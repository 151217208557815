<template lang="pug">
<router-link :to="getUrl" class="luser">
  LAvatar(:url="photo")
  .luser__username {{ username }}
  .luser__usertype {{ isadv ? $t('Рекламодатель') : $t('Пользователь') }}
</router-link>
</template>

<script>

export default {
  props: {
    isadv: {
      type: Boolean,
      default: false
    },
    username: {
      type: String,
      default: null
    },
    photo: {
      type: String,
      default: null
    },
    uuid: {
      type: String,
      default: '4ea6d75e-c8d8-495c-a327-c9f94342da24'
    }
  },
  components: {
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar')
  },
  computed: {
    getUrl () {
      let layout = this.$route.meta.layout
      if (layout === 'profile') return `/app/profile/user/${this.uuid}`
      else return `/app/user/${this.uuid}`
    }
  }
}
</script>

<style lang="scss">
.luser {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  text-decoration: none;
  &__username {
    margin-top: 15px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: .03em;
    text-align: center;
    color: #000;
  }
  &__usertype {
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    color: #000;
  }
}
</style>
