import { render, staticRenderFns } from "./LCheckbox.vue?vue&type=template&id=34a27644&lang=pug&"
import script from "./LCheckbox.vue?vue&type=script&lang=js&"
export * from "./LCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./LCheckbox.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports