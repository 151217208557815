<template lang="pug">
.ltabs
  .ltabs__header
    .ltabs__header-item(
      v-for="tab in tabs"
      v-if="tab.$options._componentTag === 'LTab'"
      :class="{ 'ltabs__header-item_active': tab.isActive }"
      @click="selectTab(tab)"
    ) {{ tab.title }}

  slot(name="gap")

  .ltabs__content
    slot(name="default")

</template>

<script>

export default {

  props: {
  },
  created () {
    this.tabs = this.$children
  },
  mounted () {
    console.log(this)
  },
  data () {
    return {
      tabs: []
    }
    
  },
  methods: {
    selectTab (selectedTab) {
      this.$emit('click', selectedTab.stub)
      this.tabs.forEach(tab => {
        tab.isActive = (tab.title == selectedTab.title);
      });
    }
  }

}
</script>

<style lang="scss">
.ltabs {
  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    &-item {
      cursor: pointer;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      &_active {
        font-weight: 700;
        position: relative;
        &:after {
          position: absolute;
          bottom: 0;
          top: 22px;
          left: 0;
          right: 0;
          height: 3px;
          width: 100%;
          content: '';
          background: #C31A14;
          opacity: .6;
        }
      }
    }
  }
}
</style>
