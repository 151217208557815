<template lang="pug">
.lshare-popup
  LPopup(@close="$emit('close')")
    h3.mb-4 {{ $t('Поделись публикацией') }}
    template(v-if="!showShareForFriend")
      .lshare-popup__social
        .lshare-popup__social-item(style="background: #455C81"
          @click="share('//vk.com/share.php?url=')")
          LIcon(name="vk" color="#fff")

        .lshare-popup__social-item(style="background: #EE8208"
          @click="share('//connect.ok.ru/offer?url=')")
          LIcon.lshare-popup__social-ok(name="ok" color="#fff")

        .lshare-popup__social-item(style="background: #3B5998"
          @click="share('//www.facebook.com/sharer.php?u=')")
          LIcon.lshare-popup__social-fb(name="fb" color="#fff")

      //- if USER ADVERTISER
      template(v-if="SETTINGS.type !== 'user'")
        h3.mb-2.mt-2 Поделиться в группах лайк системы
        .lshare-popup__social
          .lshare-popup__social-item(
            v-for="group, index in groups"
            :key="group.name"
            :style="`background: ${group.backgroundColor}`"
            @click="checkGroupItem(index)"
          )
            LIcon(
              :name="group.name"
              :color="group.iconColor"
              :class="`lshare-popup__social-${group.name}`"
            )
            .lshare-popup__social-check(v-if="group.checked")

      LButton.mt-3(theme="blue") Поделиться в соцсетях
      LButton.mt-1(@click="showShareForFriend = true") Поделиться с другом
      LButton.mt-1(@click="copyUrl" v-if="!coppied") Копировать ссылку
      LButton.mt-1(@click="copyUrl" v-if="coppied") Ссылка скопирована

    template(v-else)
      .lshare-popup__friends.mb-2
        .lshare-popup__friend(v-for="friend, index in friends" v-if="!!friend" @click="toggleFriend(index)")
          LAvatar(:url="friend.photo.preview")
          .lshare-popup__social-check(v-if="friend.checked")
          span {{ friend.name }}

      LSearch(v-model="friendNameQuery" :global="false")
      div.friends__select(v-if="friendsSelectList.length > 0")
        div.friends__select-item(v-for="item in friendsSelectList" @click="setChecked(item.uuid)") {{ item.name }}

      LButton.mt-1(@click="copyUrl") Поделиться

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LSharePopup',
  components: {
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LSearch: () => import(/* webpackChunkName: "global" */ '@/components/global/LSearch'),
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar')
  },
  props: {
    link: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.setFriends()
  },
  data () {
    return {
      coppied: false,
      showShareForFriend: false,
      socials: [
        {
          name: "vk",
          checked: false,
          backgroundColor: "#455C81",
          iconColor: "#fff",
          shareLink: '//vk.com/share.php?url='
        },
        {
          name: "ok",
          checked: false,
          backgroundColor: "#EE8208",
          iconColor: "#fff",
          shareLink: '//connect.ok.ru/offer?url='
        },
        {
          name: "fb",
          checked: false,
          backgroundColor: "#3B5998",
          iconColor: "#fff",
          shareLink: '//www.facebook.com/sharer.php?u='
        }
      ],
      groups: [
        {
          name: "youtube",
          checked: false,
          backgroundColor: "#FF0000",
          iconColor: "#fff"
        },
        {
          name: "tiktok",
          checked: false,
          backgroundColor: "#000",
          iconColor: "#fff"
        },
        {
          name: "fb",
          checked: false,
          backgroundColor: "#3B5998",
          iconColor: "#fff"
        },
        {
          name: "instagram",
          checked: false,
          backgroundColor: "#000",
          iconColor: "#fff"
        },
        {
          name: "twitter",
          checked: false,
          backgroundColor: "#56ABEE",
          iconColor: "#fff"
        },
        {
          name: "vk",
          checked: false,
          backgroundColor: "#455C81",
          iconColor: "#fff"
        },
        {
          name: "telegram",
          checked: false,
          backgroundColor: "#28AED6",
          iconColor: "#fff"
        },
        {
          name: "linkedin",
          checked: false,
          backgroundColor: "#007AB9",
          iconColor: "#fff"
        },
        {
          name: "pinterest",
          checked: false,
          backgroundColor: "#CB2027",
          iconColor: "#fff"
        },
        {
          name: "viber",
          checked: false,
          backgroundColor: "#7D519D",
          iconColor: "#fff"
        },
        {
          name: "whatsapp",
          checked: false,
          backgroundColor: "#39A944",
          iconColor: "#fff"
        },
        {
          name: "ok",
          checked: false,
          backgroundColor: "#EE8208",
          iconColor: "#fff"
        }
      ],
      friendsFull: [],
      friendNameQuery: null,
      friendsSelectList: []
    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS']),
    friends () {
      return this.friendsFull
    }
  },
  methods: {
    ...mapActions('user', ['getFriends']),
    toggleFriend (index) {
      this.friends[index].checked = !this.friends[index].checked
    },
    setChecked (uuid) {
      let indx = this.friendsFull.map((item) => { return item.uuid; }).indexOf(uuid)
      this.friendsFull[indx].checked = !this.friendsFull[indx].checked
      this.friendNameQuery = null
    },
    setFriends () {
      // add check field
      this.friendsFull = []
      this.getFriends().then(res => {
        let friends = res.data.data.data
        friends.forEach(friend => {
          friend['checked'] = false
        })
        this.friendsFull = friends
      })
    },
    checkGroupItem (index) {
      this.$set(this.groups[index], 'checked', !this.groups[index].checked)
    },
    checkSocialItem (index) {
      this.$set(this.socials[index], 'checked', !this.socials[index].checked)
    },
    share (socialUrl) {
      const url = `${socialUrl}https://cdn.likeeng.uk/share/post/${this.link}`
      window.open(url, '', 'toolbar=0,status=0,width=626,height=436')
    },
    copyUrl () {
      navigator.clipboard.writeText(`https://cdn.likeeng.uk/share/post/${this.link}`).then(() => {
        this.coppied = true
        setTimeout(() => {
          this.coppied = false
        }, 2000)
      }, (err) => {
        console.error('Async: Could not copy text: ', err);
      });
    }
  },
  watch: {
    friendNameQuery (newVal) {
      if (newVal !== null && newVal.toString().length > 2) {
        let buffer = []
        this.friendsFull.forEach(item => {
          if (item.name.toString().toLowerCase().indexOf(this.friendNameQuery.toString().toLowerCase()) + 1) {
            buffer.push(item)
          }
        })

        this.friendsSelectList = buffer
      } else {
        this.friendsSelectList = []
      }
    }
  }
}
</script>

<style lang="scss">
.friends__select {
  border-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border: solid 1px #888A99;
  position: absolute;
  background: #fff;
  width: 100%;
  max-width: 315px;

  &-item {
      height: 44px;
      display: flex;
      align-items: center;
      cursor: pointer;
  }
}
.lshare-popup {
  &__friends {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  &__friend {
    position: relative;
    & span {
      font-size: 14px;
      white-space: nowrap;
      font-weight: bold;
      padding-top: 10px;
      display: block;
      padding-bottom: 10px;
    }
  }
  & .lpopup__body {
    width: auto;
    max-width: 100%;
  }
  &__social {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 385px;
    &-check {
      border-radius: 50%;
      background: #02FD48;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 53px;
      left: 53px;
      &:before {
        display: block;
        background: #fff;
        content: '';
        width: 6px;
        height: 3px;
        position: relative;
        transform: rotate(45deg);
        top: 6px;
        left: 2px;
      }
      &:after {
        display: block;
        background: #fff;
        content: '';
        width: 8px;
        height: 3px;
        position: relative;
        transform: rotate(-45deg);
        top: 4px;
        left: 6px;
      }
    }
    &-item {
      width: 52px;
      height: 52px;
      cursor: pointer;
      margin: 0 5px 15px 5px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }
  &__social {
    &-ok {
      width: 40px;
      height: 40px;
    }
    &-fb {
      width: 40px;
      height: 40px;
    }
    &-youtube {
      width: 40px;
      height: 40px;
    }
  }
  
}
</style>