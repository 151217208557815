<template lang="pug">
.ltimer {{ time }}

</template>

<script>
export default {
  name: 'LTimer',
  props: {
    count: {
      // how many ms to end timer
      type: Number,
      default: 0
    },
    timestamp: {
      // start/end timer time
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      timerId: null,
      time: null
    }
  },
  created () {
    const leaveTime = (this.timestamp + this.count) - Date.now()
    let inSeconds = 0
    // store all time in seconds
    if (leaveTime > 0) inSeconds = Math.trunc(leaveTime / 100)
    else this.$emit('end')
    this.setTimeString(inSeconds)
    this.$emit('start')
    this.timerId = setInterval(() => {
      this.setTimeString(inSeconds -= 1)
    }, 1000)
  },
  methods: {
    clear () {
      clearInterval(this.timerId)
    },
    setTimeString (seconds) {
      let m = 0
      let s = 0
      if (seconds >= 0) {
        s = seconds
        if (seconds > 59) {
          m = Math.trunc(seconds / 60)
          s = Math.trunc(seconds % 60)
        }

        if (m < 10 ) m = `0${m}`
        if (s < 10) s = `0${s}`
        this.time = `${m}:${s}`

      } else {
        this.$emit('end')
        this.clear()
      }
    }
  },
  unmounted () {
    this.clear()
  },
  destroy() {
    this.clear()
  }
}
</script>

<style lang="scss">
.ltimer {
}
</style>