<template lang="pug">
.lavatar(
    :class="[`lavatar_${size}`]"
    @click="$emit('click')"
  )
  .lavatar__container
    p(v-if="!hasUrl")
    img.lavatar__image(
      :src="url"
    )

  slot(name="default")

</template>

<script>

export default {
  props: {
    url: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'medium'
    }
  },
  components: {
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon')
  },
  computed: {
    hasUrl () {
      if (this.url !== null) return true
      return false
    }
  },
  watch: {
    url (newVal) {
      console.log(newVal)
    }
  }
}
</script>

<style lang="scss">
.lavatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  background: #757373;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  
  cursor: pointer;
  position: relative;
  &__container {
    overflow: hidden;
    border-radius: 50%;
  }
  &_medium {
    width: 68px;
    height: 68px;
  }
  &_small {
    width: 42px;
    height: 42px;
  }
  &_micro {
    width: 35px;
    height: 35px;
  }
  &_large {
    width: 168px;
    height: 168px;
  }
  &__image {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}
</style>
