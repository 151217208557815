<template lang="pug">
.ltab(v-if="isActive")
  slot(name="default")

</template>

<script>

export default {

  props: {
    title: {
      required: true,
      type: String,
      default: null
    },
    selected: {
      type: Boolean,
      default: false
    },
    stub: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.isActive = this.selected
  },
  data () {
    return {
      isActive: false
    }
  }

}
</script>

<style lang="scss">
.ltabs {
  &__header {
    width: 100%;
    display: flex;
  }
}
</style>
