<template lang="pug">
.lcategories
  .lcategories__title {{ $t('категории') }}

  LAccordion(v-if="categoriesList.length > 0" v-for=" category in categoriesList")
    template(slot="title") {{ category.name }}
    template(slot="list")
      .lcategories__list(v-for="subcategory in category.categories")
        .lcategories__item(@click="setCategory(subcategory.slug)" :class="{'lcategories__item_active': selectedSlug === subcategory.slug }") {{ subcategory.name }}
        .lcategories__sub-item(
          v-if="subcategory.categories !== null"
          v-for="ssubcategory in subcategory.categories"
          @click="setCategory(ssubcategory.slug)"
          :class="{'lcategories__item_active': selectedSlug === ssubcategory.slug }"
        ) {{ ssubcategory.name }}

</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LCategories',
  components: {
    LAccordion: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAccordion'),
  },
  created () {
    this.categories().then(response => {
      this.categoriesList.push(...response.data.data.data)
    })
    this.selectedSlug = this.$route.params.slug || null
  },
  data () {
    return {
      categoriesList: [],
      selectedSlug: null
    }
  },
  methods: {
    ...mapActions('dictonary', [ 'categories']),
    setCategory (slug) {
      this.$emit('change', slug)
      this.$router.push(`/app/category/${slug}`)
      this.selectedSlug = slug
    }
  },
  wathc: {
    $route (newVal) {
      console.log("CHANGE ROUTE")
      this.selectedSlug = newVal.params.slug || null
    }
  }
}
</script>

<style lang="scss">
.lcategories {
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 60px 30px;
  margin-bottom: 30px;
  &__list {
    margin-left: 10px;
  }
  &__item {
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #0A156A;
      text-decoration: underline;
    }
    &_active {
      color: #0A156A;
      text-decoration: underline;
    }
  }
  &__sub-item {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #0A156A;
      text-decoration: underline;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 30px;
    text-transform: capitalize;
  }
}
</style>