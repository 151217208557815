<template lang="pug">
.ldropdown-language
  LDropdown
    template(slot="value") {{ getCurrentLang() }}
    template(slot="items")
      .ldropdown-language__item(
        v-for="item, index in langs"
        @click="changeLanguage(index)"
        v-if="item.short !== currentLang"
      ) {{ $t(`${item.full}`) }}

</template>

<script>
// TODO i18n
import Cookie from 'js-cookie'

export default {
  name: 'LSelectLanguage',
  components: {
    LDropdown: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LDropdown'),
  },
  data () {
    return {
      langs: [
        { 
          full: "English",
          short: "en"
        },
        { 
          full: "Russian",
          short: "ru"
        },
        { 
          full: "French",
          short: "fr"
        },
        { 
          full: "Spanish",
          short: "es"
        },
        { 
          full: "German",
          short: "de"
        },
        { 
          full: "Italian",
          short: "it"
        },
      ],
      currentLang: "en"
    }
  },
  mounted () {
    const lang = Cookie.get('LANG')
    if (!!lang) {
      this.currentLang = lang
      this.$i18n.locale = lang
    }
  },
  methods: {
    changeLanguage (index) {
      // setCookie, set i18n
      this.currentLang = this.langs[index].short
      this.$i18n.locale = this.currentLang
      Cookie.set('LANG', this.currentLang)
    },
    getCurrentLang () {
      return this.currentLang
    }
  }
}
</script>


<style lang="scss">
.ldropdown-language {
  margin-left: 5%;
  & > .ldropdown {
    height: auto;
    & > .ldropdown__selected {
      border: none;
      text-transform: capitalize;
      background: transparent;
    }
  }
  &__item {
    padding: 5px 15px;
    &:first-child {
      padding-top: 10px;
    }
    &:last-child {
      padding-bottom: 10px;
    }
    &:hover {
      background: #EAEAEA;
      cursor: pointer;
    }
  }
}
</style>
