<template lang="pug">
header.lheader
  a.lheader__logo(
    href="/"
    alt="Likeeng main page")
    img(src="@/assets/public/images/logo.svg")

  LSearch.lheader__search
  LLanguage.lheader__language
  LHeaderNotification.lheader__notification
  LButton.lheader__to-main(
    @click="$router.push('/app')"
    size="small"
    v-if="hasToMainPage"
  ) НА ГЛАВНУЮ
  LHeaderUser.lheader__user(v-if="!hasToMainPage")

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'TheHeader',
  components: {
    LSearch: () => import(/* webpackChunkName: "global" */ '@/components/global/LSearch'),
    LLanguage: () => import(/* webpackChunkName: "ui" */ '@/components/global/LDropDownLanguage'),
    LHeaderNotification: () => import(/* webpackChunkName: "global" */ '@/components/global/LHeaderNotification'),
    LHeaderUser: () => import(/* webpackChunkName: "global" */ '@/components/global/LHeaderUser'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
  },
  computed: {
    hasToMainPage () {
      return this.$route.meta.layout === 'profile' || this.$route.meta.layout === 'messages'
    }
  },
  methods: {
    ...mapActions('user', ['logout']),
  }
}
</script>

<style lang="scss">
.lheader {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 20px 5%;
  justify-content: space-between;
  @media (min-width: 768px) {
    //height: 120px;
  }
  @media (min-width: 1200px) {
    padding: 20px 0;
  }
  &__logo {
    margin-bottom: 20px;
    overflow: hidden;
    width: 36px;
    @media (min-width: 960px) {
      width: 220px;
      margin-bottom: 0;
    }
  }
  &__search {
    width: 100%;
    order: 4;
    @media (min-width: 960px) {
      order: 1;
      width: 260px;
      margin-bottom: 0;
    }
  }
  &__language {
    order: 1;
    margin-bottom: 20px;
    margin-left: 20px !important;
    @media (min-width: 960px) {
      order: 2;
      margin-bottom: 0;
    }
  }
  &__notification {
    order: 2;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: 10px;
    @media (min-width: 960px) {
      order: 3;
      margin-bottom: 0;
      margin-left: 0;
    }
  }
  &__user {
    order: 3;
    margin-bottom: 20px;
    @media (min-width: 960px) {
      order: 4;
      margin-bottom: 0;
    }
  }
  &__to-main {
    order: 5;
  }
}
</style>