<template lang="pug">
.lstatistic-block
  .lstatistic-block__title
    slot(name="title")

  .lstatistic-block__list(
    :class="[{'lstatistic-block__list_show': isShowMore }]"
  )
    slot(name="list")

  .lstatistic-block__show-more(@click="toggleShowMore" v-if="!!$slots.list && $slots.list.length > 3" :class="[{'lstatistic-block__show-more_hide': isShowMore }]")
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 8.76342L11.6156 17.6377L19.2389 8.7694L17.3042 7.00532L11.6175 13.6211L5.93533 7L4 8.76342Z" fill="white"/>
    </svg>


  .lstatistic-block__total(v-if="!!$slots.total")
    slot(name="total")

</template>

<script>

export default {
  props: {
  },
  data () {
    return {
      isShowMore: false
    }
  },
  components: {
  },
  mounted () {
    console.log(this.$slots.list)
  },
  methods: {
    toggleShowMore () {
      this.isShowMore = !this.isShowMore
      this.$emit('opened', this.isShowMore)
    }
  }
}
</script>

<style lang="scss">
.lstatistic-block {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 44px;
  transition: all ease-in-out .2s;
  &__show-more {
    width: 32px;
    height: 32px;
    background: #C31A14;
    border-radius: 50%;
    margin: 0 auto;
    cursor: pointer;
    transition: all ease-in-out .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    &_hide {
      transform: rotate(180deg);
    }
  }
  &__item {
    min-height: 90px;
    margin-bottom: 10px;
  }
  &__title {
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 30px;
  }
  &__list {
    max-height: 300px;
    overflow: hidden;
    transition: all ease-in-out .2s;
    &_show {
      max-height: unset;
      height: auto;
      transition: all ease-in-out .2s;
    }
  }
  &__total {
    background: #FAF9F9;
    padding: 35px 60px;
    margin-top: 30px;
  }
}
</style>
