<template lang="pug">
.lautocomplite(
    :class="[{'lautocomplite_show': hasQuery }]"
  )
  .lautocomplite__input
    LInput(
      ref="ipt"
      :error="error"
      :placeholder="placeholder"
      v-model="query"
      :preloader="preloader"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @input="$emit('input', query)"
    )

  .lautocomplite__dropdown
    slot(name="items")

</template>

<script>
  // TODO async import

export default {
  props: {
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    preloader: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput'),
  },
  data () {
    return {
      query: this.value,
      show: false
    }
  },
  computed: {
    hasQuery () {
      return this.query !== null && this.query.length > 0
    }
  },
  watch: {
    value (newVal) {
      this.query = newVal
    }
  }
}
</script>

<style lang="scss">
.lautocomplite {
  position: relative;
  &_show {
    & > .lautocomplite__dropdown {
      pointer-events: auto;
      opacity: 1;
    }
  }
  &__dropdown {
    position: absolute;
    opacity: 0;
    background: #fff;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    pointer-events: none;
    overflow: hidden;
    z-index: 1000;
  }
}
</style>
