<template lang="pug">
.linput(
    :class="[{'linput_focused': focused}, {'linput_error': error}]"
    @click="$refs.ipt.focus()"
  )
  input(
    :placeholder="placeholder"
    :type="getType"
    v-model="val"
    ref="ipt"
    @input="$emit('input', val)"
    @focus="focused = true; $emit('focus'); readonly = false"
    @blur="focused = false; $emit('blur'); readonly = true"
    :autocomplite="autocomplite"
    :readonly="readonly"
    @keyup.enter="$emit('enter')"
  )

  template(v-if="type === 'password'")
    LIcon(
      @click="showPassword = !showPassword"
      :name="showPassword ? 'eye' : 'eye-close'"
      color="#E5E5E5"
    )

  template(v-if="preloader")
    LIcon.linput__preloader(
      name="preloader"
      color="#E12323"
    )

  .linput__slot-right(v-if="!!$slots.right")
    slot(name="right")

</template>

<script>
  // TODO async import

export default {
  props: {
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: String,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    preloader: {
      type: Boolean,
      default: false
    },
    autocomplite: {
      type: String,
      default: 'on'
    }
  },
  components: {
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon')
  },
  created () {
    if (this.autocomplite === 'off') this.readonly = true
    else this.readonly = false
  },
  data () {

    return {
      showPassword: false,
      focused: false,
      val: this.value,
      readonly: true
    }
  },
  computed: {
    getType () {
      if (this.type === "password") {
        return this.showPassword ? "text" : "password"
      }

      return this.type
    }
  },
  watch: {
    value (newVal) {
      this.val = newVal
    }
  }
}
</script>

<style lang="scss">
.linput {
  display: flex;
  height: 60px;
  background: #FFFFFF;
  border: 2px solid #888A99;
  border-radius: 10px;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  & input {
    width: 100%;
    outline: none;
    border: 0;
    background: transparent;
    &::placeholder {
      text-transform: uppercase;
      color: #757373;
      font-size: 18px;
      opacity: 1;
      letter-spacing: .03em;
    }
  }
  & .licon {
    width: 38px;
    height: 38px;
  }
  &_focused {
    border-color: #1E4BD2;
  }
  &_error {
    border-color: #C91818;
    & input {
      color: #C91818;
    }
  }
}

</style>
