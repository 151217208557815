<template lang="pug">
<router-link :to="getUrl" class="lpartner">
  LAvatar(:url="photo")
  .lpartner__name {{ name }}
  .lpartner__description {{ description }}
</router-link>
</template>

<script>

export default {
  props: {
    description: {
      type: String,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    photo: {
      type: String,
      default: null
    },
    uuid: {
      type: String,
      default: null
    }
  },
  components: {
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar')
  },
  computed: {
    getUrl () {
      let layout = this.$route.meta.layout
      if (layout === 'profile') return `/app/profile/partner/${this.uuid}`
      else return `/app/partner/${this.uuid}`
    }
  }
}
</script>

<style lang="scss">
.lpartner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  text-decoration: none;
  &__name {
    margin-top: 15px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: .03em;
    text-align: center;
    text-decoration: none;
    color: #000;
  }
  &__description {
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    max-width: 160px;
    text-decoration: none;
    color: #000;
  }
}
</style>
