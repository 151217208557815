<template lang="pug">
.ldropdown(
  :class="[{ 'ldropdown_show': show }, { 'ldropdown_invalid': error}]"
  @click="toggle()"
  v-click-outside="vcoConfig"
  ref="container"
)
  .ldropdown__selected
    .ldropdown__placeholder(v-if="!$slots.value") {{ placeholder }}
    slot(name="value")
    LIcon(name="arrow-down")

  .ldropdown__droplist(:style="styles" ref="droplist")
    slot(name="items" :class="[{ 'ldropdown_icon_rotate': show }]")

</template>

<script>
export default {
  name: 'LSelect',
  props: {
    placeholder: {
      type: String,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon')
  },
  data () {
    return {
      show: false,
      left: 0,
      vcoConfig: {
        handler: this.outsideHide,
        events: ['mousedown'],
        isActive: true,
        capture: false
      }
    }
  },
  mounted () {
    //const container = this.$refs['container'].getBoundingClientRect()
    //const droplist = this.$refs['droplist'].getBoundingClientRect()
    //if (droplist.width > container.width) this.left = (container.width - droplist.width) / 2
  },
  computed: {
    styles () {
      return {
        left: `${this.left}px`,
      }
    }
  },
  methods: {
    outsideHide () { if (this.show) this.show = false },
    toggle () {
      this.show = !this.show
    }
  },
}
</script>

<style lang="scss">
.ldropdown {
  height: 60px;
  position: relative;
  z-index: 1000;

  &_invalid {
    & > .ldropdown__selected {
      border-color: #E12323;
    }
  }

  &_show {
    overflow: visible;
    & > .ldropdown__selected  {
      border-color: #1E4BD2;
    }
    & > .ldropdown__selected > .licon {
      transform: rotate(180deg);
    }
    & > .ldropdown__droplist {
      top: 52px;
      opacity: 1;
      pointer-events: auto;
    }
  }
  &__selected {
    display: flex;
    height: 60px;
    background: #FFFFFF;
    border: 2px solid #888A99;
    border-radius: 10px;
    align-items: center;
    padding-left: 15px;
    & > .licon {
      margin-left: auto;
      margin-right: 10px;
      & path {
        fill: #E12323;
      }
    }
  }
  &__droplist {
    min-width: 100%;
    top: 72px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background: #FFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    overflow: hidden;
    transition: all .2s ease-in-out;
    & > div {
      min-height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-left: 15px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
  &__placeholder {
    text-transform: uppercase;
    color: #757373;
    font-size: 18px;
    letter-spacing: .03em;
  }
}
</style>