<template lang="pug">
.lcheckbox(:class="[{ 'lcheckbox_checked': checked }, { 'lcheckbox_invalid': error}]")
  .lcheckbox__input(@click.self="check")
  .lcheckbox__label
    slot(name="default")

</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.checked = this.value
  },
  data () {
    return {
      checked: null
    }
  },
  methods: {
    check () {
      this.checked = !this.checked
      this.$emit('input', this.checked)
    }
  },
  watch: {
    value (newVal) {
      this.checked = newVal
    }
  }
}
</script>

<style lang="scss">
.lcheckbox {
  display: flex;
  &__input {
    width: 40px;
    height: 40px;
    border: 2px solid #888A99;
    border-radius: 10px;
    background-color: #fff;
    flex-shrink: 0;
  }
  &_checked {
    & .lcheckbox__input {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMi4zMTQ1IDQuNjg1NDlDMjMuMjI4NSA1LjU5OTQ5IDIzLjIyODUgNy4wODEzNiAyMi4zMTQ1IDcuOTk1MzZMMTEuNTQ4NSAxOC43NjEzQzEwLjYzNDYgMTkuNjc1MyA5LjE1MjY4IDE5LjY3NTMgOC4yMzg2OSAxOC43NjEzTDEuNjg1NDkgMTIuMjA4MUMwLjc3MTUwMiAxMS4yOTQxIDAuNzcxNTAyIDkuODEyMjUgMS42ODU0OSA4Ljg5ODI2QzIuNTk5NDkgNy45ODQyNyA0LjA4MTM2IDcuOTg0MjcgNC45OTUzNiA4Ljg5ODI2TDkuODkzNjIgMTMuNzk2NUwxOS4wMDQ2IDQuNjg1NDlDMTkuOTE4NiAzLjc3MTUgMjEuNDAwNSAzLjc3MTUgMjIuMzE0NSA0LjY4NTQ5WiIgZmlsbD0iI0M5MTgxOCIvPgo8L3N2Zz4K");
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &_invalid {
    & .lcheckbox__input {
      border-color: #E12323;
    }
  }
  &__label {
    padding-left: 10px;
    display: flex;
    align-items: center;
    width: auto;
  }
}
</style>
